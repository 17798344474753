<Header>
  <Hero>
    <h1 class="text-white" translate="PAGE_HOME_HERO_TITLE"></h1>
    <p translate="PAGE_HOME_HERO_BODY"></p>

    <div class="flex flex-wrap justify-center lg:justify-start gap-3 mt-10">
      <Button href="/koppelingen" color="red" [shadow]="false">
        {{ 'PAGE_HOME_HERO_BUTTON_TRIAL' | translate }}
      </Button>
      <!--Button href="#informatie-aanvragen" color="white" [outline]="true">
        {{ 'PAGE_HOME_HERO_BUTTON_INFO' | translate }}
      </Button-->
    </div>
  </Hero>
</Header>

<!-- note that defer doesn't do much, because these components are very light anyway (more info on defer: https://angular.dev/guide/defer) -->
@defer {
  <Apps></Apps>

  <Intro>
    <h1 translate="PAGE_HOME_INTRO_TITLE"></h1>
    <p translate="PAGE_HOME_INTRO_BODY_1"></p>
    <p translate="PAGE_HOME_INTRO_BODY_2"></p>
    <MoreLink [href]="'SUPERFOOTER_LINK_ABOUT_COMBIDESK_URL' | translate">{{ 'PAGE_HOME_INTRO_ABOUT' | translate }}</MoreLink>
  </Intro>

  <Logos></Logos>

  <ContentIllustration
      [alt]="'IMG_ALT_BENEFITS' | translate"
      [illustration]="{
        name: 'benefits',
        height: 336,
        width: 480
      }">
    <h2 translate="PAGE_HOME_CONTENT_BENEFITS_TITLE"></h2>
    <p translate="PAGE_HOME_CONTENT_BENEFITS_BODY"></p>
  <!--  <MoreLink href="/over-combidesk/#voordelen">+ Lees meer</MoreLink>-->
  </ContentIllustration>

  <ContentIllustration
      [alt]="'IMG_ALT_INSTALLATION' | translate"
      [illustration]="{
        name: 'installation',
        height: 357,
        width: 480
      }"
      [inverse]="true">
    <h2 translate="PAGE_HOME_CONTENT_INSTALLATION_TITLE"></h2>
    <p translate="PAGE_HOME_CONTENT_INSTALLATION_BODY"></p>
  <!--  <MoreLink href="/over-combidesk/#installatie">+ Lees meer</MoreLink>-->
  </ContentIllustration>

  <ContentIllustration
      [alt]="'IMG_ALT_SECURITY' | translate"
      [illustration]="{
        name: 'security',
        height: 334,
        width: 420
      }">
    <h2 translate="PAGE_HOME_CONTENT_SECURITY_TITLE"></h2>
    <p translate="PAGE_HOME_CONTENT_SECURITY_BODY"></p>
  <!--  <MoreLink href="/over-combidesk/#beveiliging">+ Lees meer</MoreLink>-->
  </ContentIllustration>

  <ContentIllustration
      [alt]="'IMG_ALT_PRICE' | translate"
      [illustration]="{
        name: 'price',
        height: 403,
        width: 420
      }"
      [inverse]="true">
    <h2 translate="PAGE_HOME_CONTENT_PRICING_TITLE"></h2>
    <p translate="PAGE_HOME_CONTENT_PRICING_BODY"></p>
  </ContentIllustration>

  <CustomerService></CustomerService>

  <Cta></Cta>

  <Footer></Footer>
}