import { Component, OnInit } from "@angular/core";
import { LocalStorageKey } from "../../local-storage/local-storage-keys";
import { ActivatedRoute, Router } from "@angular/router";
import { OAuthService } from "../../services/oauth/oauth.service";
import { LocalStorageService } from "../../local-storage/local-storage.service";
import { OAuth } from "../../model";
import { CombiPackageService } from "../../services/combipackage/combipackage.service";
import { UserService } from "../../services/user/user.service";

@Component({
  selector: 'app-shopify-oauth-result',
  template: ''
})
export class ShopifyOAuthResultComponent implements OnInit {

  constructor(private localStorageService: LocalStorageService,
              private combiPackageService: CombiPackageService,
              private userService: UserService,
              private oAuthService: OAuthService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  async ngOnInit(): Promise<void> {
    const packageInternalKey = this.route.snapshot.params["package"];
    const queryParams = this.route.snapshot.queryParams;
    const apiKey = await this.combiPackageService.getCombiPackageOAuthKeyByInternalKey(packageInternalKey, queryParams["shop"]);

    const combiPackageLoadUserResult = await this.userService.loadUserByAPIOAuthKeyAndUrlService(apiKey, queryParams["shop"]);

    let redirectAfterOAuth = this.localStorageService.get<string>(LocalStorageKey.REDIRECTTO_AFTER_OAUTHRESULT);
    let oAuthRequestResult = this.localStorageService.get<OAuth>(LocalStorageKey.OAUTHRESULT);

    if (redirectAfterOAuth?.indexOf("?") > -1) {
      // separate the queryParams to prevent url encoding of ? and &
      redirectAfterOAuth = redirectAfterOAuth.substring(0, redirectAfterOAuth.indexOf("?"));
    } else {
      redirectAfterOAuth = `shopifydashboard/${packageInternalKey}`;
    }

    if (!oAuthRequestResult) {
      oAuthRequestResult = <OAuth>{
        packageName: packageInternalKey,
        url: queryParams["shop"],
        externalApiId: combiPackageLoadUserResult.userAPI?.externalApiId
      };
    }

    oAuthRequestResult.secret = queryParams["code"];
    oAuthRequestResult.customParams = queryParams;

    if (oAuthRequestResult.authUrl) {
      if (this.userService.getUser()) {
        const userApi = await this.oAuthService.result(oAuthRequestResult);
        if (userApi) {
          this.localStorageService.set(LocalStorageKey.CURRENT_USERAPI, userApi);
        }
      }
      this.localStorageService.remove(LocalStorageKey.OAUTHRESULT);
      this.localStorageService.remove(LocalStorageKey.REDIRECTTO_AFTER_OAUTHRESULT);
    }

    this.router.navigate([redirectAfterOAuth], {queryParams});
  }

}
