// see app.constants.js
// eslint-disable-next-line no-shadow
export enum LocalStorageKey {
  USER = "STORAGEKEY_USER", // kept the same as the old frontend on purpose (easy switching), but not the other keys
  ADMIN_USER = "STORAGEKEY_ADMIN_USER",
  RECOMMENDED_BY_URL_PARAM = "RECOMMENDED_BY_URL_PARAM",
  REDIRECTTO_AFTER_OAUTHRESULT = "REDIRECTTO_AFTER_OAUTHRESULT",
  SHOPIFY_STORE = "SHOPIFY_STORE",
  SHOPIFY_APPAPIKEY = "SHOPIFY_APPAPIKEY",
  OAUTHRESULT = "OAUTHRESULT",
  OAUTHFLOW_COMPLETED = "OAUTHFLOW_COMPLETED",
  CURRENT_USERAPI = "CURRENT_USERAPI",
  INTERCOM_BOOTED = "INTERCOM_BOOTED", // TODO unused
  CP_USERCOMBIPACKAGE = "CP_USERCOMBIPACKAGE",
  CP_COMBIPACKAGE = "CP_COMBIPACKAGE",
  CP_EMAIL = "CP_EMAIL",
  CP_PACKAGEPARAMS = "CP_PACKAGEPARAMS"
}
