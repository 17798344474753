import { Injectable } from "@angular/core";
import { Message, MessageType } from "./message";
import { sleep } from "../../../util/sleep.util";
import { ButtonAction, ErrorObject } from "../../../services/base-http.service";
import { ErrorMessage } from "./error-modal/error-message";
import { Observable, Subject, Subscription } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  message = new Message();
  errorMessage = new ErrorMessage();

  updateObservable: Observable<ButtonAction>;
  private updateSubject: Subject<ButtonAction> = new Subject();

  private hideTimeoutId: any;
  private snoozing = false;

  constructor() {
    this.updateObservable = this.updateSubject.asObservable();
  }

  subscribeToUpdates(next: (buttonAction: ButtonAction) => void): Subscription {
    return this.updateObservable.subscribe(next);
  }

  triggerUpdate(buttonAction: ButtonAction) {
    this.updateSubject.next(buttonAction);
  }

  showSuccessMessage(message: string): void {
    this.showMessage("SUCCESS", message);
  }

  showInfoMessage(message: string): void {
    this.showMessage("INFO", message);
  }

  showWarningMessage(message: string): void {
    this.showMessage("WARN", message);
  }

  showErrorMessage(message: string): void {
    this.showMessage("ERROR", message);
  }

  showErrorModal(errorObject: ErrorObject): void {
    this.errorMessage.errorObject = errorObject;
    this.errorMessage.hide = false;
    this.errorMessage.active = true;
  }

  hasActiveStickyMessage(): boolean {
    return this.message.active &&
        (this.message.type === "WARN" || this.message.type === "ERROR");
  }

  snooze(): void {
    this.snoozing = true;
  }

  stopSnoozing(): void {
    this.snoozing = false;
  }

  snoozed(): boolean {
    return this.snoozing;
  }

  async hideMessage() {
    if (this.message.active) {
      this.message.hide = true;
      // delay, so the opacity transform is shown (see duration-300 in message.component.html)
      await sleep(300);
      this.message.active = false;
    }
  }

  async hideErrorMessage() {
    if (this.errorMessage.active) {
      this.errorMessage.hide = true;
      // delay, so the opacity transform is shown (see duration-300 in message.component.html)
      await sleep(300);
      this.errorMessage.active = false;
    }
  }

  actionButtonClicked(buttonAction: ButtonAction) {
    this.triggerUpdate(buttonAction);
  }

  private showMessage(type: MessageType, message: string): void {
    if (!this.snoozing) {
      clearTimeout(this.hideTimeoutId);
      this.message.message = message;
      this.message.type = type;
      this.message.hide = false;
      this.message.active = true;
      if (type === "SUCCESS" || type === "INFO") {
        const duration = 1000 + (this.countWords(message) * 300);
        this.hideTimeoutId = setTimeout(() => this.hideMessage(), duration);
      }
    }
  }

  private countWords(message: string): number {
    return message.split(/\W+/).length;
  }
}
